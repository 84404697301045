import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;
import * as _mapValues3 from "lodash/mapValues";
var _mapValues = "default" in _mapValues3 ? _mapValues3.default : _mapValues3;
var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = convertBooleansOnAttrs;
var _mapValues2 = _interopRequireDefault(_mapValues);

/**
 * Convert "true" and "false" string attributes values
 * to corresponding Booleans
 */
function convertBooleansOnAttrs(attrs) {
  return (0, _mapValues2.default)(attrs, val => {
    if (val === "true") {
      return true;
    }
    if (val === "false") {
      return false;
    }
    return val;
  });
}
exports = exports.default;
export default exports;